import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'embryo-CollectionGallery',
  templateUrl: './CollectionGallery.component.html',
  styleUrls: ['./CollectionGallery.component.scss']
})
export class CollectionGalleryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
