import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import Swal, {SweetAlertResult} from 'sweetalert2';
import {HttpHeaders} from '@angular/common/http';
//import {NotifierService} from 'angular-notifier';
import {JwtHelperService} from '@auth0/angular-jwt';
import {MatDialog, MatSnackBar} from '@angular/material';

const helper = new JwtHelperService();

@Injectable({
    providedIn: 'root'
})
export class ConstantsProvider {

    constructor(
                private router: Router,
                private _snackBar: MatSnackBar,
                private _matDialog: MatDialog
    ) {
    }

    public tokenName = 'smcs-access-token';
    public claimName = 'smcs-id-token';
    public url = 'https://smcsapi.openclass.ng/';
    //public url = 'http://localhost:56994/';

    public setReturnUrl(url: string): void {
        localStorage.setItem('nextReturnUrl', url);
    }

    public getReturnUrl(clearOld): string {
        const nextReturnUrl = localStorage.getItem('nextReturnUrl');
        if (clearOld) {
            localStorage.removeItem('nextReturnUrl');
        }

        let url = '/admin-panel';

        if (nextReturnUrl && nextReturnUrl !== 'null' && nextReturnUrl !== 'undefined' && nextReturnUrl !== '/') {
            url = nextReturnUrl;
        }
        return url;
    }

    public setToken(token): void {
        localStorage.setItem(this.tokenName, token);
    }

    public setClaim(claim): void {
        localStorage.setItem(this.claimName, claim);
    }

    public isUserAuthenticated(): boolean {
        const claim = this.getClaim();
        try {
            return !helper.isTokenExpired(claim);
        } catch (exception) {
        }
        return false;
    }

    public getToken(): string {
        return localStorage.getItem(this.tokenName);
    }

    public getClaim(): string {
        return localStorage.getItem(this.claimName);
    }

    public getAuthenticatedUser(): any {
        const claim = localStorage.getItem(this.claimName);
        if (claim) {
            try {
                const decoded = helper.decodeToken(claim);
                // console.log(decoded);
                return decoded;
            } catch (e) {
            }
        }

        return {
            email: '',
            permission: [],
            phone: '',
            role: '',
            fullname: ''
        };
    }


    private extractError(err): string {
        console.log(err);
        if (!err) {
            return 'Unknown error';
        }

        if (typeof err === 'string') {
            return err;
        }

        if (!err.status && !err.ok) {
            return 'Unable to connect to server, are you connected?';
        }

        if (err.error && typeof err.error === 'object') {
            if (err.error.error_description) {
                return err.error.error_description;
            }


        }

        if (err.error && typeof err.error === 'string') {
            return err.error;
        }

        if (err.status === 401 && err.statusText === 'Unauthorized') {
            this.setReturnUrl(location.href);
            this.router.navigate(['/account/login']);
        }

        if (err.message) {
            return err.message;
        }

        return err.toString();

    }

    public handleError(err: any, showError = false): string {
        const data = this.extractError(err);
        if (showError) {
            this.error(data);
        }

        console.log(data);

        return data;
    }

    getAuthHeaderOption(contentType = 'application/json'): any {
        const headers = new HttpHeaders({
            'Content-Type': contentType,
            'authorization': 'Bearer ' + this.getToken(),
            'Access-Control-Allow-Origin': '*'
        });
        return {headers: headers};
    }

    getUploadHeader(): any {
        return new HttpHeaders({
            authorization: 'Bearer ' + this.getToken(),
        });
    }

    getRawDataHeaderOption(): any {
        const headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            'authorization': 'Bearer ' + this.getToken(),
            'Access-Control-Allow-Origin': '*'
        });
        return {headers: headers};
    }

    getOrdinaryHeaderOption(): any {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        });
        return {headers: headers};
    }

    getOrdinaryHeaderUrlEncodedOption(): any {
        const headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            'Access-Control-Allow-Origin': '*'
        });
        return {headers: headers};
    }

    succeeded(message: any): void {
        this._snackBar.open(message, 'OK', {
            verticalPosition: 'top',
            duration: 3000,
            panelClass: ['green', 'darken-3']
        });
        // this.notifier.notify('success', message);
    }

    error(message: any): void {
        this._snackBar.open(message, 'CLOSE', {
            verticalPosition: 'top',
            duration: 3000,
            panelClass: ['red', 'darken-3']
        });
        // this.notifier.notify('error', message);
    }

    
}
