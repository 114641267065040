import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { ConstantsProvider } from 'src/app/Services/constants-provider.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthProvider } from 'src/app/Services/auth-provider.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'embryo-SignIn',
  templateUrl: './CommonSignIn.component.html',
  styleUrls: ['./CommonSignIn.component.scss']
})
export class CommonSignInComponent implements OnInit {
loginForm: FormGroup;
@BlockUI() blockUI: NgBlockUI;
  constructor(
    private constantsService: ConstantsProvider,
    private authService: AuthProvider,
    private _formBuilder: FormBuilder
  ) { }

  ngOnInit() {
       this.loginForm = this._formBuilder.group({
      username   : ['', [Validators.required,Validators.email]],
      password: ['', Validators.required]
  });
  }
  attemptLogin(): void {
   
    const controls = this.loginForm.controls;

		// check form
		if (this.loginForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
    }
    this.blockUI.start('Login in...')
    const formData = this.loginForm.getRawValue();
    formData.grant_type = 'password';
    formData.scope = 'openid email phone profile offline_access roles';

    const d = [];
    Object.entries(formData).forEach(entry => {
        d.push(`${entry[0]}=${entry[1]}`);
    });
    
    this.authService.login(d.join('&')).subscribe(data => {
        
        this.authService.setClaim(data.id_token);
        this.authService.setToken(data.access_token);
        this.blockUI.stop()
        location.href = this.authService.getReturnUrl(true);
    }, error => {
      this.blockUI.stop()
      let errMessage =  this.constantsService.handleError(error, true);
      Swal.fire(
        errMessage,
        'Kindly make the neccessary corrections and try again',
        'error'
        
      )
    });
}

}
