import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'embryo-SubscribeTwo',
  templateUrl: './SubscribeTwo.component.html',
  styleUrls: ['./SubscribeTwo.component.scss']
})
export class SubscribeTwoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
