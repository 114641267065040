import { Component, OnInit } from '@angular/core';
import { AuthProvider } from 'src/app/Services/auth-provider.service';

@Component({
  selector: 'embryo-HeaderUserProfileDropdown',
  templateUrl: './HeaderUserProfileDropdown.component.html',
  styleUrls: ['./HeaderUserProfileDropdown.component.scss']
})
export class HeaderUserProfileDropdownComponent implements OnInit {
  isAuthenticated: boolean = false;
 

   constructor(public authService: AuthProvider
   ) { }

   ngOnInit() {
    this.isAuthenticated = this.authService.isUserAuthenticated()
   }

   Logout(){
     alert('loging out')
     
    return this.authService.logout()
   }

   

}
