import { Injectable } from '@angular/core';

/*
 * Menu interface
 */
export interface Menu {
	state: string;
	name?: string;
	type?: string;
	icon?: string;
	children?: Menu[];
}

const HeaderOneItems= [
  // {
  //   state: "home",
  //   name: "HOME",
  //   type: "sub",
  //   icon: "home",
  //   children: [
  //     {  
  //       state: 'home', 
  //       name: 'HOME ONE',
  //       type: 'link',
  //       icon: 'home'
  //     },
  //     {  
  //       state: 'home-two', 
  //       name: 'HOME TWO',
  //       type: 'link',
  //       icon: 'home'
  //     }, {
  //       state:'home-three',
  //       name: 'HOME THREE',
  //       type: 'link',
  //       icon: 'home'
  //     }
  //   ]
  // },
  // {
  //   state: "",
  //   name : "STORE",
  //   type: "sub",
  //   icon: "pages",
  //   children: [
  //     {  
  //       state: 'products/men/4', 
  //       name: 'PRODUCT DETAILS',
  //       type: 'link',
  //       icon: 'arrow_right_alt'
  //     },
  //     {  
  //       state: 'cart', 
  //       name: 'CART',
  //       type: 'link',
  //       icon: 'arrow_right_alt'
  //     },
  //     {  
  //       state: 'checkout', 
  //       name: 'CHECKOUT',
  //       type: 'link',
  //       icon: 'arrow_right_alt'
  //     },
  //     {  
  //       state: 'checkout/payment', 
  //       name: 'PAYMENT',
  //       type: 'link',
  //       icon: 'arrow_right_alt'
  //     }
  //   ]
  // },
  {
    state:'products',
    name:"Courses",
    type:"sub",
    mega:true,
    icon: 'party_mode',
    children: [
      {  
        state: 'products/acca', 
        name: 'ACCA',
        type: 'sub',
        icon: 'arrow_right_alt',
        children:[
          {  
            state: 'products/acca', 
            queryState:'knowledge',
            name: 'Applied Knowledge',
            type: 'queryParams',
            icon: 'arrow_right_alt',
          },
          {  
            state: 'products/acca', 
            queryState:'skills',
            name: 'Applied Skills',
            type: 'queryParams',
            icon: 'arrow_right_alt',
          },
          {
            state: 'products/acca', 
            queryState:'professional',  
            name: 'Applied Professional',
            type: 'queryParams',
            icon: 'arrow_right_alt',
          }
        
        ]
      },
      {  
        state: 'products/ican', 
        name: 'ICAN',
        type: 'sub',
        icon: 'arrow_right_alt',
        children:[
          {  
            state: 'products/ican', 
            queryState:'foundation',
            name: 'Foundation Level',
            type: 'queryParams',
            icon: 'arrow_right_alt',
          },
          {  
            state: 'products/ican', 
            queryState:'skills',
            name: 'Skills',
            type: 'queryParams',
            icon: 'arrow_right_alt',
          },
          {  
            state: 'products/ican', 
            queryState:'professional',
            name: 'Professional',
            type: 'queryParams',
            icon: 'arrow_right_alt',
          }
        ]
      },
      // {  
      //   state: 'gadgets', 
      //   name: 'GADGETS',
      //   type: 'sub',
      //   icon: 'arrow_right_alt',
      //   children:[
      //     {  
      //       state: 'products/gadgets', 
      //       queryState:'Headphone', 
      //       name: 'HEADPHONE',
      //       type: 'queryParams',
      //       icon: 'arrow_right_alt',
      //     },
      //     {  
      //       state: 'products/gadgets', 
      //       queryState:'Smartphone',
      //       name: 'SMARTPHONE',
      //       type: 'queryParams',
      //       icon: 'arrow_right_alt',
      //     },
      //     {  
      //       state: 'products/gadgets', 
      //       queryState:'Watch',
      //       name: 'WATCH',
      //       type: 'queryParams',
      //       icon: 'arrow_right_alt',
      //     },
      //     {  
      //       state: 'products/gadgets', 
      //       queryState:'Speaker',
      //       name: 'SPEAKER',
      //       type: 'queryParams',
      //       icon: 'arrow_right_alt',
      //     }
      //   ]
      // },
      // {  
      //   state: 'accessories', 
      //   name: 'ACCESSORIES',
      //   type: 'sub',
      //   icon: 'arrow_right_alt',
      //   children:[
      //     {  
      //       state: 'products/accessories', 
      //       queryState:'Laptap',
      //       name: 'LAPTOP ACCESSORIES',
      //       type: 'queryParams',
      //       icon: 'arrow_right_alt',
      //     },
      //     {  
      //       state: 'products/accessories', 
      //       queryState:'Belts',
      //       name: 'BELTS',
      //       type: 'queryParams',
      //       icon: 'arrow_right_alt',
      //     },
      //     {  
      //       state: 'products/accessories', 
      //       queryState:'Jewellery', 
      //       name: 'JEWELLERY',
      //       type: 'queryParams',
      //       icon: 'arrow_right_alt',
      //     }
      //   ]
      // }
    ]
  },
 
  {
    state:'products/acca',
    name:"ACCA",
    type:"link",
    icon: 'party_mode'
  },
  {
    state:'products/ican',
    name:"ICAN",
    type:"link",
    icon: 'party_mode'
  },
  {
    state:'products/cisa',
    name:"CISA",
    type:"link",
    icon: 'party_mode'
  },
  {
    state:'products/pmp',
    name:"PMP",
    type:"link",
    icon: 'party_mode'
  },
  
//   {
//   state: "pages",
//   name: "PAGES",
//   type: "sub",
//   icon: "pages",
//   children: [
//      {  state: 'about', 
//         name: 'ABOUT',
//         type: 'link',
//         icon: 'arrow_right_alt',
//      },
//      {  state: 'term-condition', 
//         name: 'TERM AND CONDITION',
//         type: 'link',
//         icon: 'arrow_right_alt',
//      },
//      {  
//         state: 'privacy-policy', 
//         name: 'PRIVACY POLICY',
//         type: 'link',
//         icon: 'arrow_right_alt',
//       },
//       {  
//         state: 'blogs/detail', 
//         name: 'BLOG DETAIL',
//         type: 'link',
//         icon: 'arrow_right_alt',
//       },
//       {  
//         state: 'faq', 
//         name: 'FAQ',
//         type: 'link',
//         icon: 'arrow_right_alt',
//       },
//       {  
//         state: 'not-found', 
//         name: '404 PAGE',
//         type: 'link',
//         icon: 'arrow_right_alt',
//       },
//       {  
//          state: 'account/profile', 
//          name: 'User Profile',
//          type: 'link',
//          icon: 'arrow_right_alt',
//       },
//       {
//         state:'session',
//         name:"SESSION",
//         type:"subChild",
//         icon: 'supervised_user_circle',
//         children: [
//             {  
//             state: 'session/signin', 
//             name: 'SIGN IN',
//             type: 'link',
//             icon: 'arrow_right_alt',
//             },
//             {  
//                 state: 'session/signup', 
//                 name: 'REGISTER',
//                 type: 'link',
//                 icon: 'arrow_right_alt',
//             },
//             {  
//                 state: 'session/forgot-password', 
//                 name: 'FORGET PASSWORD',
//                 type: 'link',
//                 icon: 'arrow_right_alt',
//             },
//             {  
//                 state: 'session/thank-you', 
//                 name: 'THANK YOU',
//                 type: 'link',
//                 icon: 'arrow_right_alt',
//             }
//           ]
//        }
//     ],
// },
  {
    state:'contact',
    name:"CONTACT US",
    type:"link",
    icon: 'perm_contact_calendar'
  },
  {
    state:'admin-panel',
    name:"My Account",
    type:"link",
    icon: 'perm_identity'
  }
];

const FooterOneItems= [
  {
     state:'',
     name:"ABOUT",
     type:"sub",
     icon: '',
     children: [
      // {  
      //    state: 'about', 
      //    name: 'ABOUT',
      //    type: 'link',
      //    icon: 'arrow_right_alt',
      // },
      {  
         state: 'term-condition', 
         name: 'TERM AND CONDITION',
         type: 'link',
         icon: 'arrow_right_alt',
      },
      {  
        state: 'privacy-policy', 
        name: 'PRIVACY POLICY',
        type: 'link',
        icon: 'arrow_right_alt',
      },
      {  
        state: 'faq', 
        name: 'FAQ',
        type: 'link',
        icon: 'arrow_right_alt',
       },
       {  
         state:'contact',
         name:"CONTACT US",
         type:"link",
         icon: 'perm_contact_calendar',
       }
    ]
  }, 
  {
    state:'',
    name:"SESSION",
    type:"sub",
    icon: '',
    children: [
        {  
        state: 'session/signin', 
        name: 'SIGN IN',
        type: 'link',
        icon: 'arrow_right_alt',
        },
        {  
            state: 'session/signup', 
            name: 'REGISTER',
            type: 'link',
            icon: 'arrow_right_alt',
        },
        {  
            state: 'session/forgot-password', 
            name: 'FORGET PASSWORD',
            type: 'link',
            icon: 'arrow_right_alt',
        },
        // {  
        //     state: 'session/thank-you', 
        //     name: 'THANK YOU',
        //     type: 'link',
        //     icon: 'arrow_right_alt',
        // }
    ]
  },
  {
    state:'',
    name:"CATEGORIES",
    type:"sub",
    icon: '',
    children: [
      {  
        state: 'products/acca', 
        name: 'ACCA',
        type: 'link',
        icon: 'arrow_right_alt',
      },
      {  
        state: 'products/ican', 
        name: 'ICAN',
        type: 'link',
        icon: 'arrow_right_alt',
      }
      
    ]
  },
  {
    state:'',
    name:"SOCIAL",
    type:"sub",
    icon: '',
    children: [
      {
        state: 'https://www.facebook.com/', 
        name: 'Facebook',
        type: 'social_link',
        icon: 'arrow_right_alt',
      },
      {
        state: 'https://twitter.com/', 
        name: 'Twitter',
        type: 'social_link',
        icon: 'arrow_right_alt',
      },
      {
        state: 'https://www.youtube.com/', 
        name: 'Youtube',
        type: 'social_link',
        icon: 'arrow_right_alt',
      },
      {
        state: 'https://plus.google.com', 
        name: 'Google Plus',
        type: 'social_link',
        icon: 'arrow_right_alt',
      }
    ]
  }

]

@Injectable()
export class MenuItems {

   /*
    * Get all header menu
    */
   getMainMenu(): Menu[] {
      return HeaderOneItems;
   }

   /*
    * Get all footer menu
    */
   getFooterOneMenu(): Menu[] {
      return FooterOneItems;
   }
}
