import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'embryo-DownloadAppSection',
  templateUrl: './DownloadAppSection.component.html',
  styleUrls: ['./DownloadAppSection.component.scss']
})
export class DownloadAppSectionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
